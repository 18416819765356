/* You can add global styles to this file, and also import other style files */
$gutter: 1rem; //for primeflex grid system
@import "assets/layout/styles/layout/layout.scss";

/* PrimeNG */
@import "../node_modules/primeng/resources/primeng.min.css";
@import "../node_modules/primeflex/primeflex.scss";
@import "../node_modules/primeicons/primeicons.css";

/* Demos */
@import "assets/demo/styles/flags/flags.css";
@import "assets/demo/styles/badges.scss";

 /* Global Variables */
$gutter: 1rem; // for PrimeFlex grid system
$primary-color: #1A4D8C; // primary color used throughout the application
$secondary-color: #f1f1f1; // secondary color used throughout the application

/* Import Styles */
@import "assets/layout/styles/layout/layout.scss";
@import "../node_modules/primeng/resources/primeng.min.css";
@import "../node_modules/primeflex/primeflex.scss";
@import "../node_modules/primeicons/primeicons.css";
@import "assets/demo/styles/flags/flags.css";
@import "assets/demo/styles/badges.scss";

/* width */
::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;

}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.p-password {
  width: 100%;
}

.p-menu {
  width: unset;
}

.p-accordion .p-accordion-content {
  padding: 1rem;
}

.main-container-div,
.details-container,
.sidebar-menu {
  padding: 73px 1rem 0;

  @media (min-width:576px) {
    padding: 90px 1rem 0;
  }
}

.top-sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 90px;
}

.font-size-10 {
  font-size: 10px !important;
}

.font-size-12 {
  font-size: 12px !important;
}

.font-size-14 {
  font-size: 14px !important;
}


.pdf-panel {
  border: 1px solid black !important;
}

.pdf-accordion .p-accordion-tab{
  border: 1px solid black !important;
  border-radius: 5px !important;
  margin-bottom: 1rem !important;
}

.shelf-product-indicator {
  color: #1A4D8C !important
}

#pdf-container {  
  position: absolute;
  left: -9999px;
}

.full-width-pdf-container {
  width: calc(100vw - 125px);
}

.medium-width-pdf-container {
  width: calc(100vw - 500px);
}
  
.section-header{
  background-color: #e8ecf0;
}